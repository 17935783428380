import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import TextLink from 'src/components/TextLink'
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group'
import TextLockup from 'src/components/TextLockup'
import Container from 'src/components/Container'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { colors, typography, animations, mediaQueries as mq, util } from 'src/styles'

const timingFunctionIn = 'cubic-bezier(0.0, 0.0, 0.2, 1)'
const timingFunctionOut = 'cubic-bezier(0.4, 0.0, 1, 1)'

const Wrapper = styled.div`
	.modal-enter {
		.modal-box {
			transition: opacity 500ms ${ timingFunctionIn };
			opacity: 1;
		}
		.modal-title {
			transition: opacity 500ms ${ timingFunctionIn } 400ms;
			transform-delay: 200ms;
			opacity: 1;

		}
		.modal-left,
		.modal-right {
			transition: opacity 500ms ${ timingFunctionIn }, transform 300ms ${ timingFunctionOut };
			transition-delay: 800ms;
			opacity: 1;
			transform: none;
		}
	} 
	. modal-enter-active {
		.modal-box {
			transition: opacity 500ms ${ timingFunctionIn };
			opacity: 1;
		}
		.modal-title {
			transition: opacity 500ms ${ timingFunctionIn } 400ms;
			transform-delay: 200ms;
			opacity: 1;

		}
		.modal-left,
		.modal-right {
			transition: opacity 500ms ${ timingFunctionIn }, transform 300ms ${ timingFunctionOut };
			transition-delay: 800ms;
			opacity: 1;
			transform: none;
		}
	}
	.modal-enter-done {
		.modal-box {
			transition: opacity 500ms ${ timingFunctionIn };
			transform-delay: 200ms;
			opacity: 1;
		}
		.modal-title {
			transition: opacity 500ms ${ timingFunctionIn } 400ms;
			opacity: 1;

		}
		.modal-left,
		.modal-right {
			transition: opacity 500ms ${ timingFunctionIn }, transform 300ms ${ timingFunctionOut };
			transition-delay: 800ms;
			opacity: 1;
			transform: none;
		}
	}
	.modal-exit {
		.modal-box {
			transition: opacity 300ms ${ timingFunctionIn };
			transition-delay: 700ms;
			opacity: 1;	
		}
		.modal-title {
			transition: opacity 500ms ${ timingFunctionIn };
			transform-delay: 300ms;
			opacity: 1;

		}
		.modal-left,
		.modal-right {
			transition: opacity 300ms ${ timingFunctionIn }, transform 300ms ${ timingFunctionOut };
			transform: translate3d(0, 40px, 0);
			opacity: 0;
		}
	}
	.modal-exit-active {
		.modal-box {
			transition: opacity 300ms ${ timingFunctionIn };
			transition-delay: 700ms;
			opacity: 0;
		}
		.modal-title {
			transition: opacity 500ms ${ timingFunctionIn };
			transition-delay: 300ms;
			opacity: 0;

		}
		.modal-left,
		.modal-right {
			transition: opacity 300ms ${ timingFunctionIn }, transform 300ms ${ timingFunctionOut };
			transform: translate3d(0, 40px, 0);
			opacity: 0;
		}
	}
	.modal-exit-done {

	}

`


const ModalContainer = styled(Container)`
	${ mq.smallAndBelow } {
		background: ${ colors.bgColor };
	}
`

const timeout = 500
const opacityTimeout = 2000
// const timingFunctionIn = 'cubic-bezier(0.4, 0.0, 0.4, 1)'
// const timingFunctionOut = 'cubic-bezier(0.4, 0.0, 0.4, 1)'

// const timingFunction = 'ease-in-out'

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
	display: flex;
	align-items: center;
	justify-content: center;
	${ mq.smallAndBelow } {
		overflow: auto;
	}

`

const TitleWrapper = styled.div`
	> hr {
		width: calc(100% + 14px);
		border-color: ${ colors.hrColorStrong };
		margin-bottom: 0;
		margin: 0 -7px;
	}
	border-bottom: 1px solid ${ colors.hrColorStrong };
	padding: 36px 7px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background: ${ colors.bgColor };
`

const TitleWrap = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	h2,
	h3 {
		display: inline-block;
	}
	h2 {
		line-height: 55px;
		margin: 0;
	}
	h3 {
		margin-left: 6px;
	}
`

const CloseWrap = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	svg {
		display: block;
		width: 28px;
		margin-top: 13px;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
		// * {
		// 	fill: ${ colors.white };
		// }
		${ mq.smallAndBelow } {
			width: 30px;
		}
	}
	&:hover {
		cursor: pointer;
		svg {
      transform: scale(1.1);
    }
	}
`

const ContentWrap = styled.div`
	height: 100%;
	padding: 48px 0 0 7px;
	${ mq.smallAndBelow } {
		padding-top: 35px;
		padding-left: 0;
	}
`

const LeftBlock = styled.div`
	// flex-grow: 1;
	h1 {
		margin-bottom: 28px;
	}

	${ mq.smallAndBelow } {
		padding-bottom: 50px;
		border-bottom: 1px solid ${ colors.hrColor };
	}
`

const LinksWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	a {
		display: block;
		&:first-of-type {
			margin-top: 0;
		}
		margin-top: 10px;
	}

`

const RightBlock = styled.div`
	// flex-grow: 0;
	h1 {
		padding-bottom: 14px;
		border-bottom: 1px solid ${ colors.hrColorStrong };
		&:nth-of-type(2) {
			margin-top: 100px;
		}
		margin-bottom: 0;
		${ mq.smallAndBelow } {
			&:nth-of-type(1) {
				margin-top: 0;
			}
		}
	}

	${ mq.smallAndBelow } {
		padding: 50px 0;
	}
`

const TeamWrap = styled(TextLockup)`
	ul {
		li {
			list-style-type: none;
			border-bottom: 1px solid ${ colors.hrColor };
			// padding: 10px 0;
			p {
				${ typography.bodyMedium }
				position: relative;
				padding-top: 5px;
				padding-bottom: 5px;
				i {
					position: absolute;
					top: 5px;
					left: 0;
					line-height: 2em;
					font-style: normal;
					visibility: visible;
					opacity: 1;
					transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
				}
				b{
					position: absolute;
					top: 5px;
					left: 0;
					line-height: 2em;
					visibility: hidden;
					opacity: 0;
					transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
					font-family: ${ typography.serifFont };
					font-weight: normal;
				}
				&:hover {
					cursor: default;
					i {
						visibility: hidden;
						opacity: 0;
					}
					b {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
`

const ClientsWrap = styled(TextLockup)`
	ul {
		li {
			list-style-type: none;
			border-bottom: 1px solid ${ colors.hrColor };
			padding: 10px 0;
		}
	}
`

const ServiceItem = styled.p`
	${ typography.bodyMedium}
	margin: 0;
	border-bottom: 1px solid ${ colors.hrColor };
	padding-top: 5px;
	padding-bottom: 6px;
`

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${ colors.bgColor };
	z-index: 1;
	display: none;
	transition: opacity ${ opacityTimeout }ms ${ timingFunctionIn };
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		opacity: .5;
	` : `` }
`

const ModalBox = styled.div`
	background: ${ colors.bgColor };
	padding: 0 0 20px;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	opacity: 0;
	overflow: auto;


`

const ModalEntrance = styled.div`
	opacity: 0;

	${ ({ applyTransform }) => applyTransform === true ? `
		transform: translate3d(0, 40px, 0);
  ` : ``}
`

const ModalTakeover = ({ className, open, toggleModal, type, title, content }) => {
	let clientCheck = false;

	if ( typeof title.client === 'string' ) {
		clientCheck = true;
	}

	return (
		<Wrapper id='modal-takeover'>
			<CSSTransition
			  in={open && content && clientCheck}
			  timeout={{
			    enter: 2000,
			    exit: 1000
			  }}
			  classNames='modal'
			  unmountOnExit
			  mountOnEnter
			>
			  {transitionStatus => {
			  	console.log('transitionStatus: ', transitionStatus)
			  	console.log('open: ', open)
			  	
			  	return (
					<ModalWrapper className={className}>
						<Overlay onClick={() => toggleModal(false)} transitionStatus={transitionStatus}/>
						<ModalBox className="modal-box" transitionStatus={transitionStatus} open={open}>
					  	<ModalContainer>
					  		<ModalEntrance className="modal-title" delay={11} transitionStatus={transitionStatus} applyTransform={false}>
									<TitleWrapper>
										<hr/>
										<TitleWrap>
											{title.client && (
												<h2>{title.client}</h2>
											)}
											{title.projectTitle && (
												<h3>{title.projectTitle}</h3>
											)}
										</TitleWrap>

										<CloseWrap onClick={() => toggleModal(false)}>

											<svg  viewBox="0 0 64 64">
												<path fillRule="evenodd"  fill="rgba(239,239,239,0.7)"
												 d="M42.254,19.936 L43.669,21.350 L21.135,43.885 L19.720,42.470 L42.254,19.936 Z"/>
												<path fillRule="evenodd"  fill="rgba(239,239,239,0.7)"
												 d="M19.722,21.332 L21.136,19.918 L43.670,42.452 L42.256,43.867 L19.722,21.332 Z"/>
												<path fillRule="evenodd"  stroke="rgba(239,239,239,0.7)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
												 d="M31.844,1.937 C48.412,1.937 61.844,15.369 61.844,31.937 C61.844,48.506 48.412,61.938 31.844,61.938 C15.275,61.938 1.844,48.506 1.844,31.937 C1.844,15.369 15.275,1.937 31.844,1.937 Z"/>
											</svg>
											
										</CloseWrap>
									</TitleWrapper>
								</ModalEntrance>
								<ContentWrap>
										<Grid
											small='[1]'
											medium='[7] 1 [4]'
											large='[7] 1 [4]'
											vAlign='top'
											>
										<LeftBlock>
											{type === 'project' ? (
												<Fragment>
													<ModalEntrance  className="modal-left" delay={14} transitionStatus={transitionStatus} applyTransform={true}>
														<div>
															<h1>Information</h1>
														</div>
														<TextLockup
															transitionIn={false}
															text={content.information}
														/>
														{content.links ? (
															<LinksWrapper>
																{content.links.map((link, index) => {
																	return (
																		<TextLink
																			key={'button-' + index}
																			to={link.to}
																			external={link.to}
																			target={link.openInNewTab ? '_blank' : ''}
																			title={link.label}
																		>
																			{link.label}
																		</TextLink>
																	)
																})}
															</LinksWrapper>
														) : false}
													</ModalEntrance>
												</Fragment>
											) : (
												<ModalEntrance className="modal-left"  delay={20} transitionStatus={transitionStatus} applyTransform={true}>
													<TextLockup
														// entranceDelay={14}
														{...content.information}
													/>
												</ModalEntrance>
											)}
											
										</LeftBlock>
										{ type === 'project' ? (

											<RightBlock>
												<ModalEntrance className="modal-right"  delay={14} transitionStatus={transitionStatus} applyTransform={true}>
													<div>
														<h1>Services</h1>
													</div>
													<div>
														{content.services.map((service, index) => {
															return (<ServiceItem key={index}>{service.tag}</ServiceItem>)
														})}
													</div>
												</ModalEntrance>
												<ModalEntrance  className="modal-right" delay={14} transitionStatus={transitionStatus} applyTransform={true}>
													<div><h1 style={{ marginTop: '50px' }}>Team</h1></div>
													<TeamWrap
														// entranceDelay={16}
														text={content.team}
														textSize="bodyMedium"
													/>
												</ModalEntrance>
											</RightBlock>
											) : (
												<RightBlock>
													<ModalEntrance  className="modal-right" delay={20} transitionStatus={transitionStatus} applyTransform={true}>
														<div><h1>Select Clients</h1></div>
														<ClientsWrap
															// entranceDelay={16}
															text={content.services}
															textSize="bodyMedium"
														/>
													</ModalEntrance>
												</RightBlock>

											)}
										</Grid>

								</ContentWrap>
							</ModalContainer>
						</ModalBox>
				  </ModalWrapper>
				)}}
			</CSSTransition>
		</Wrapper>
	)
}

export default ModalTakeover
